import { SitesCatalogsBlock } from "@ihr-radioedit/inferno-webapi";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { Store } from "@inferno/renderer-shared-core";
import { PageBlockInterface } from "../Block.component";
import { ILog, Site } from "@ihr-radioedit/inferno-core";
import { toJS } from "mobx";
import CatalogItem from "./CatalogItem.component";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { Container, Heading, Grid } from "../../ui";
import { getDisplayHints } from "../../services/Sites.utils";
import classnames from "classnames";

const log = ILog.logger(" Catalog Component");
interface CatalogProps {
  store?: Store;
}

export function isCatalogBlock(block: any): block is SitesCatalogsBlock {
  return !!block.catalogRefs;
}

export const catalogBlockDisplayDataProviderMap: Record<
  string,
  (site: Site, translator: UseTranslationResponse<"translation">) => { title: string; url: string }
> = {
  podcast: (site, translator) => {
    const { t } = translator;
    return { title: t("podcasts"), url: reverseRoute(site, "podcasts") || "" };
  },

  playlist: (site, translator) => {
    const { t } = translator;
    return {
      title: t("playlists").charAt(0).toUpperCase() + t("playlists").slice(1),
      url: reverseRoute(site, "playlists") || "",
    };
  },
  curated: (site, translator) => {
    const { t } = translator;
    return {
      title: t("playlists").charAt(0).toUpperCase() + t("playlists").slice(1),
      url: reverseRoute(site, "playlists") || "",
    };
  },
  track: (site, translator) => {
    const { t } = translator;
    return { title: t("tracks"), url: reverseRoute(site, "tracks") || "" };
  },
  album: (site, translator) => {
    const { t } = translator;
    return { title: t("albums"), url: reverseRoute(site, "albums") || "" };
  },
  artist: (site, translator) => {
    const { t } = translator;
    return { title: t("artists"), url: reverseRoute(site, "artists") || "" };
  },
  station: (site, translator) => {
    const { t } = translator;
    return { title: t("stations"), url: reverseRoute(site, "stations") || "" };
  },
  default: () => ({ title: "", url: "" }),
};

export const getCatalogBlockDisplayData = (
  site: Site,
  type: string,
  translator: UseTranslationResponse<"translation">,
) => {
  return type && typeof catalogBlockDisplayDataProviderMap[type.toLowerCase()] === "function"
    ? catalogBlockDisplayDataProviderMap[type.toLowerCase()](site, translator)
    : catalogBlockDisplayDataProviderMap.default(site, translator);
};

export const CatalogComponent = inject("store")(({ store, block }: CatalogProps & PageBlockInterface) => {
  if (!block || !store) {
    return null;
  }

  useEffect(() => store.storeBlock(block));
  type CatalogKind = "album" | "artist" | "track" | "station" | "podcast" | "playlist" | "curated";

  function isCatalogKind(s: string): s is CatalogKind {
    return ["album", "artist", "track", "station", "podcast", "playlist", "curated"].includes(s);
  }

  if (isCatalogBlock(block)) {
    const catalogType = block.catalogType;
    const items = block.catalogRefs;
    const translator = useTranslation();

    const { title, url } = getCatalogBlockDisplayData(store.site, block.catalogType, translator);
    const { tags } = block;
    const { t } = useTranslation();
    useEffect(() => {
      store.storeBlock(block);
    });

    const css = classnames("component-podcasts", {
      [`themed-block`]: tags?.includes("display-hints/themed-block"),
    });

    const headingLink = {
      text: t("view_more"),
      label: `${t("view_more_catalog_kind", { title })}`,
      url: url || "/",
    };

    const displayHints = getDisplayHints(block.tags || []);
    const viewAll = displayHints.hints?.includes("display-hints/hide-more") ?? false;
    const isHomePage = displayHints.hints?.includes("display-hints/catalog-block-homepage") ?? false;
    let noOfItems = isHomePage ? displayHints.columns : items.length;
    noOfItems = noOfItems ?? 0;

    if (isCatalogKind(catalogType)) {
      return (
        <Container className={css}>
          {items.length ? (
            <Heading level={2} link={viewAll ? headingLink : undefined}>
              {!!store.site && `${store.site.sections.general?.name} ${title}`}
            </Heading>
          ) : null}
          <Grid columns={displayHints.columns}>
            {items.slice(0, noOfItems).map((catalog, i) => (
              <CatalogItem catalog={catalog} catalogType={catalogType} key={i} />
            ))}
          </Grid>
        </Container>
      );
    } else {
      // If we don't know what it is, and it's not falsy, log.
      log.error("Unknown item block", toJS(block.catalogType));
    }
  }
  return null;
});

export default CatalogComponent;
