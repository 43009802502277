import loadable from "@loadable/component";
import { inject } from "mobx-react";
import {
  mediaServerCatalogImage,
  mediaServerPlaylistImage,
  PodcastSizes,
  PodcastSrcSet,
  Sizes,
  SrcSet,
} from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { CatalogRecord, SitesCatalogRef } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import "../../../core/page-blocks/podcast/Podcasts.style.scss";
import {
  isCatalogAlbumRecord,
  isCatalogAmpAlbum,
  isCatalogAmpArtist,
  isCatalogAmpPlayList,
  isCatalogAmpPodcast,
  isCatalogAmpStation,
  isCatalogAmpTrack,
  isCatalogArtistRecord,
  isCatalogPlayListRecord,
  isCatalogPodcastRecord,
  isCatalogStationRecord,
  isCatalogTrackRecord,
} from "../../lib/guards";
import { imageURI } from "../../lib/catalog";

const ImageTile = loadable(() => import("../../ui/ImageTile.component"));

export const generateCatalogItemProps = ({
  title,
  img,
  url,
  catalog,
}: {
  title: string;
  img: string;
  url: string;
  catalog: SitesCatalogRef;
}) => {
  const srcset: SrcSet[] = PodcastSrcSet(img || "");
  const sizes: Sizes[] = PodcastSizes();
  const placeholderSrcset: SrcSet[] = PodcastSrcSet(placeholderImage);
  const catalogItemProps = {
    title,
    url,
    imageUrl: `${img}?ops=max(270,270),quality(70)`,
    responsiveImg: {
      src: `${img}?ops=max(270,270),quality(70)`,
      alt: `${title}-${catalog.kind}`,
      srcset,
      sizes,
      placeholderSrcset,
      initialWidth: 150,
      initialHeight: 150,
    },
  };
  return catalogItemProps;
};

interface CatalogItemProps {
  catalog: SitesCatalogRef;
  store?: Store;
  catalogType: string;
}

export const catalogBlockItemDisplayDataProviderMap: Record<
  string,
  (store: Store, catalogItem: CatalogRecord | undefined) => { title: string; url: string; img: string } | undefined
> = {
  podcast: (store: Store, catalogItem: CatalogRecord | undefined) => {
    if (isCatalogPodcastRecord(catalogItem) && isCatalogAmpPodcast(catalogItem.amp)) {
      return {
        title: catalogItem.name || "",
        url: `${store.env.NATIONAL_SITE_URL}/podcast/${catalogItem.amp?.slug}-${catalogItem.amp?.podcastId}/`,
        img: mediaServerCatalogImage(store?.env.IMAGE_HOST, "podcast", catalogItem.amp?.podcastId, 224, 224, true),
      };
    }
  },

  playlist: (store: Store, catalogItem: CatalogRecord | undefined) => {
    if (isCatalogPlayListRecord(catalogItem) && isCatalogAmpPlayList(catalogItem.amp)) {
      return {
        title: catalogItem.amp?.name || "",
        url: `${store.env.NATIONAL_SITE_URL}/playlist/${catalogItem.amp?.slug}-${catalogItem.amp?.id}/`,
        img: mediaServerPlaylistImage(store.env.IMAGE_HOST, catalogItem.amp?.userId, catalogItem.playlistId, 224, 224),
      };
    }
  },

  album: (store: Store, catalogItem: CatalogRecord | undefined) => {
    if (isCatalogAlbumRecord(catalogItem) && isCatalogAmpAlbum(catalogItem.amp)) {
      return {
        title: catalogItem?.name || "",
        url: catalogItem?.web_link || "",
        img: imageURI(store.env.IMAGE_HOST, "album", catalogItem.amp.artistId) || "",
      };
    }
  },

  artist: (store: Store, catalogItem: CatalogRecord | undefined) => {
    if (isCatalogArtistRecord(catalogItem) && isCatalogAmpArtist(catalogItem.amp)) {
      return {
        title: catalogItem?.name || "",
        url: catalogItem?.web_link || "",
        img: imageURI(store.env.IMAGE_HOST, "artist", catalogItem.id) || "",
      };
    }
  },

  track: (store: Store, catalogItem: CatalogRecord | undefined) => {
    if (isCatalogTrackRecord(catalogItem) && isCatalogAmpTrack(catalogItem.amp)) {
      return {
        title: catalogItem?.name || "",
        url: catalogItem?.web_link || "",
        img: imageURI(store.env.IMAGE_HOST, "track", catalogItem.amp.albumId) || "",
      };
    }
  },

  station: (store: Store, catalogItem: CatalogRecord | undefined) => {
    if (isCatalogStationRecord(catalogItem) && isCatalogAmpStation(catalogItem.amp)) {
      return {
        title: catalogItem?.name || "",
        url: catalogItem?.web_link || "",
        img: imageURI(store.env.IMAGE_HOST, "station", catalogItem.amp.stationId) || "",
      };
    }
  },

  curated: (store: Store, catalogItem: CatalogRecord | undefined) => {
    return {
      title: catalogItem?.name || "",
      url: catalogItem?.web_link || "",
      img: catalogItem?.img || "",
    };
  },
  default: () => ({ title: "", url: "", img: "" }),
};

export const getCatalogItemData = (store: Store, type: string, catalogItem: CatalogRecord | undefined) => {
  return type && typeof catalogBlockItemDisplayDataProviderMap[type.toLowerCase()] === "function"
    ? catalogBlockItemDisplayDataProviderMap[type.toLowerCase()](store, catalogItem)
    : catalogBlockItemDisplayDataProviderMap.default(store, catalogItem);
};

export const CatalogItem = inject("store")(({ catalog, store, catalogType }: CatalogItemProps) => {
  if (!catalog || !store) {
    return null;
  }

  const catalogItem = catalog.record;

  if (!catalogItem) {
    return null;
  }

  const { title, url, img } = getCatalogItemData(store, catalogType, catalogItem) ?? { title: "", url: "", img: "" };

  return (
    <ImageTile
      {...generateCatalogItemProps({
        title,
        url,
        img,
        catalog,
      })}
    />
  );
});

export default CatalogItem;
